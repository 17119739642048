import { useTranslationRoute, useTranslations } from "@vadato/component"
import { useMemo } from "react"

export const useLeftSidebarMenu = () => {

    const [
        ASSOCIATION,
        DONATION,
        DONATORS,
        EMAIL,
        LOGS,
        LOGOUT
    ] = useTranslations(i18n)

    const url = useTranslationRoute()

    return useMemo(() => ([
        {
            label: DONATION,
            icon: '/assets/img/icons/donations.png',
            link: url('donations')
        },
        {
            label: EMAIL,
            icon: '/assets/img/icons/emails.png',
            link: url('email')
        },
        {
            label: LOGOUT,
            icon: '/assets/img/icons/sign-out.svg',
            link: url('auth/logout')
        },
    ]), [ASSOCIATION])
}

const i18n = [
    "Vadato.Association.Title",
    "Vadato.Donation.Title",
    "Vadato.Donators.Title",
    "Vadato.Email.Title",
    "Vadato.Logs.Title",
    "Vadato.Logout.Title",
]
