import { AppRouteProps } from "@vadato/component";
import React from "react";
import { HomeRoutes } from "./home/HomeRoutes";
import AuthLayout from "components/layout/AuthLayout";
import MainLayout from "components/layout/MainLayout";
import { Outlet } from "react-router-dom";
import { AuthRoutes } from "./auth/AuthRoutes";
import { DonationsRoutes } from "./donations/DonationsRoutes";

export const VadatoRoutes: Array<AppRouteProps> = [
    {
        path: 'auth',
        title: `Vadato.Title`,
        element: <AuthLayout />,
        children: [
            ...AuthRoutes
        ]
    },
    {
        path: '',
        title: `Vadato.Title`,
        element: <MainLayout />,
        children: [
            ...HomeRoutes,
            ...DonationsRoutes
        ]
    },
]